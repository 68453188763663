<template>
  <div class="zt-page-content">
    <!-- <div class="zt-block">
      <div class="tab_menu flex">
        <div
          v-for="(item, index) in tabList"
          :key="index"
          @click="tab(index)"
          class="flex menu_item"
          :class="{ cur: tabIndex == index }"
        >
          {{ item.name }}
        </div>
      </div>
    </div> -->

    <Txgl :key="tabIndex" :price_type="tabIndex"></Txgl>
  </div>
</template>
<script>
import Txgl from "@/components/txglCommon/Txgl.vue";
export default {
  components: {
    Txgl
  },
  data() {
    return {
      tabList: [{ name: "工厂款" }, { name: "设计师合作款" }],
      tabIndex: 0
    };
  },
  created() {
    if (this.$route.query.idx) {
      this.tabIndex = this.$route.query.idx;
    }
  },
  mounted() {},
  methods: {
    tab(index) {
      // 选项卡
      this.tabIndex = index;
      console.log(index);
    }
  }
};
</script>
<style scoped>
/*----选项卡----*/
.tab_menu {
  padding: 24px 30px;
}
.tab_menu .menu_item {
  border-radius: 24px;
  padding: 0 24px;
  height: 40px;
  line-height: 40px;
  cursor: pointer;
  font-size: 18px;
  color: #5074ee;
  border: 1px solid #5074ee;
  position: relative;
  background: #fff;
  margin-right: 30px;
}
.tab_menu .menu_item.cur {
  color: #fff;
  background-image: linear-gradient(#8362e4, #5074ee);
}
</style>
