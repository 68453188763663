<template>
  <div class="upload flex" ref="img-frm">
    <el-upload
        class="avatar-uploader"
        action="'string'"
        :auto-upload="false" 
        :show-file-list="false"
        accept="image/jpeg, image/gif, image/png"
        :before-upload="beforeUpload"
        :http-request="uploadAvatar"
        :on-change="handleCrop" 
    >
        <img v-if="newAvatar" :src="newAvatar" class="baseinfo-avatar" />
        <div v-if="newAvatar"
            class="avatar-uploader-icon flex flex-v flex-pack-center"
        >
            <i class="el-icon-plus"></i>
            <span>重新上传</span>
        </div>
        <div v-else class="avatar-uploader-icon flex flex-v flex-pack-center" style="opacity:1;">
          <i class="el-icon-plus"></i>
          <span style="padding-top:10px">上传图片</span>
        </div>
    </el-upload>
    <div class="hint">
      <div>
        1.图片大小建议不超过3MB；
        2.图片长宽比例1：1
      </div>
    </div>

    <!-- 剪裁组件弹窗 -->
    <el-dialog :append-to-body="true" :modal-append-to-body="false" :title="cropperTitle" :visible.sync="cropperModel" width="1042px" :before-close="beforeClose">
      <Cropper :img-file="file" ref="vueCropper" :fixedNumber="fixedNumber" @upload="uploadAvatar">
      </Cropper>
    </el-dialog>

  </div>
</template>
<script>
import { IMG_URL_PRE } from "@/config";
import Cropper from './Cropper';
import { uploadFile, delFile } from "@/service/common";
export default {
  components: { Cropper },
  model: {
    prop: "avatar",
    event: "change"
  },
  props: {
    avatar:String,
    size:Number,
    prefix:String,
    fixedNumber: {
      // 剪裁框比例设置
      default: function () {
        return [1, 1]
      }
    },
    width: {
      // 单图剪裁框宽度
      type: Number,
      default: 178
    },
    height: {
      // 单图剪裁框高度
      type: Number,
      default: 178
    }
  },
  data() {
    return {
      imgUrl: IMG_URL_PRE,
      file: '', // 当前被选择的图片文件
      cropperTitle:"裁剪图片",
      cropperModel: false, // 剪裁组件弹窗开关
      newAvatar:null,
    };
  },
  watch: {
      avatar:{
          handler() {
              if (this.avatar) {
                this.newAvatar = this.imgUrl+this.avatar;
              }
          },
          deep: true
      }
  },
  updated () {
    if (this.$refs.vueCropper) {
      this.$refs.vueCropper.Update()
    }
  },
  created() {
    if (this.avatar) {
      this.newAvatar = this.imgUrl+this.avatar;
    }
    //   console.log(this.avatar);
  },
  methods: {
    onChange() {
      this.$emit("change", this.newAvatar);
    },
    beforeUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < (this.size || 2);
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 "+ (this.size || 2) +"MB!");
      }
      return isLt2M;
    },
    uploadAvatar(data) {
      const _loading = this.$loading({
        lock: true,
        text: "拼命上传中....请耐心等待",
      });
      data.name = data.type.split("/")[1];
      uploadFile(data, this.prefix, {
        // onUploadProgress: event => {
        //   formData.file.percent = (event.loaded / event.total) * 100;
        //   formData.onProgress(formData.file);
        // }
      }).then(url => {
        console.log(url);
        this.newAvatar = url;
        this.cropperModel = false;
        this.onChange();
        _loading.close();
      }).catch(err => {
        _loading.close();
        console.log(err);
        this.$message.error(err.message);
      });
    },
    handleCrop (file, files) {
      const isLt2M = file.size / 1024 / 1024 > (this.size || 3);
      if (isLt2M) {
        files.pop();
        this.$message.error("上传图片大小不能超过 " + (this.size || 3) + "MB!");
      } else {
        // 点击弹出剪裁框
        this.cropperModel = true;
        this.file = file;
        this.file.url = URL.createObjectURL(file.raw);
        this.newAvatar = file.url
        console.log(file);
      }
    },
    beforeClose (done) {
      this.cropperModel = false;
      this.newAvatar = "";
    },
  }
};
</script>
<style scoped>
    .avatar-uploader {
        width: 148px;
        height: 148px;
        display: block;
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        font-size: 0;
    }
    .avatar-uploader:hover {
        border-color: #409eff;
    }
    .avatar-uploader:hover .avatar-uploader-icon {
        opacity: 1;
    }
    .avatar-uploader-icon {
        color: #5074ee;
        font-size: 24px;
        width: 148px;
        height: 148px;
        line-height: 148px;
        text-align: center;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
    }
    .avatar-uploader-icon span {
        line-height: 1;
        color: #5074ee;
        font-size: 14px;
        font-weight: 500;
    }
    .baseinfo-avatar {
        width: 148px;
        height: 148px;
        display: block;
    }

    .upload {
      position: relative;
    }
    .hint {
      width: 170px;
      font-size: 12px;
      color: #808080;
      line-height: 20px;
      padding-left: 16px;
    }
</style>