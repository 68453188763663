<template>
  <div class="zt-page-content" style="height:100%">
    <div class="select_list flex">
      <el-button @click="add_txgl()" type="primary">新增分类</el-button>
    </div>
    <div class="zt-block" style="height:calc(100% - 100px)">
      <div class="list" style="height: calc(100% - 60px);overflow-y: auto">
        <el-table
          :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
          :data="txglList"
          lazy
          row-key="id"
          style="width: 100%;">
          <el-table-column prop="name" label="分类名称">
            <template slot-scope="scope">
              <span>{{ scope.row.name }}</span>
            </template>
          </el-table-column>
          <el-table-column label="">
            <template slot-scope="scope">
              <el-button
                v-if="!scope.row.parentId"
                type="text"
                style="color:#016FFF;"
                size="small"
                @click="add_txgl(scope.row)"
              >
                +添加子分类
              </el-button>
            </template>
          </el-table-column>
          <!-- <el-table-column prop="img" label="分类图片">
            <template slot-scope="scope">
              {{ scope.row.icon }}
            </template>
          </el-table-column> -->
          <el-table-column prop="stat" label="排序">
            <template slot-scope="scope">
              <div class="sort-module">
                <!-- 分类排序 -->
                <div v-if="!scope.row.parentId" class="cats">
                  <i
                    @click="txgl_sort('up', scope.row, scope.row.data.order-1)"
                    :class="scope.row.data.order === 1 ? 'disabled' : ''"
                    class="iconfont icon-jiantou up"
                  ></i>
                  <i
                    @click="txgl_sort('down', scope.row, scope.row.data.order-1)"
                    :class="scope.row.data.order==txglList.length ? 'disabled' : ''"
                    class="iconfont icon-jiantou down"
                  ></i>
                </div>
                <!-- 商品排序 -->
                <div v-else class="goods">
                  <i
                    @click="txgl_sort('up', scope.row, scope.row.data.order-1)"
                    :class="scope.row.data.order === 1 ? 'disabled' : ''"
                    class="iconfont icon-jiantou1 up"
                  ></i>
                  <i
                    @click="txgl_sort('down', scope.row, scope.row.data.order-1)"
                    :class="scope.row.data.order==scope.row.pLength ? 'disabled' : ''"
                    class="iconfont icon-jiantou1 down"
                  ></i>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="分类产品">
            <template slot-scope="scope">
              <el-button v-if="scope.row.parentId"
                type="text"
                style="color:#016FFF;"
                size="small"
                @click="goGoodList(scope.row)"
              >
                商品列表
              </el-button>
            </template>
          </el-table-column>
          <el-table-column prop="address" label="操作" width="240">
            <template slot-scope="scope">
              <el-button v-if="comp.comp_type==3 && scope.row.parentId"
                type="text"
                style="color:#016FFF;"
                size="small"
                @click="add_saleGood(scope.row)"
              >
                添加现有商品
              </el-button>
              <el-button
                type="text"
                style="color:#016FFF;"
                size="small"
                @click="edit_txgl(scope.row.data)"
              >
                修改类目
              </el-button>
              <el-button
                type="text"
                style="color:red;"
                size="small"
                @click="del_txgl(scope.row)"
              >
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div style="padding:12px">
        <el-pagination
          background
          layout="prev, pager, next"
          :current-page.sync="queryOpt.pageNumber"
          :page-size="queryOpt.pageCount"
          :total="total"
        ></el-pagination>
      </div>
    </div>

    <el-dialog
      width="745px"
      :title="popTitle"
      :visible.sync="popVisible"
      :destroy-on-close="true"
    >
      <AddTxgl
        v-if="popTitle !== '添加现有商品' && popVisible"
        :form="ruleForm"
        :popTitle="popTitle"
        @hide="hide"
      ></AddTxgl>
      <add-good v-if="popTitle=='添加现有商品'" :set_id="set_id" :list="goodsList" @hide="hide"></add-good>
    </el-dialog>
  </div>
</template>
<script>
import { IMG_URL_PRE } from "@/config";
import { fetchTree } from '@/util';
import {
  classifyList,
  orgcatDel,
  orgcatSort,
} from "@/service/item";
import { listFxProduct } from '@/service/sharer';
import AddTxgl from "./AddTxgl.vue";
import AddGood from "./AddGood.vue";
export default {
  components: {
    AddTxgl,AddGood
  },
  props: {
    price_type: {
      required: true,
      type: [Number, String],
      default: 0
    }
  },
  data() {
    return {
      imgUrl: IMG_URL_PRE,
      popVisible: false,
      popTitle: "",
      queryOpt: {
        pageNumber: 1,
        pageCount: 15
      },
      total: 0,
      txglList: [],
      set_id: "",
      ruleForm: {},
      goodsPageConfig: {
        page: 1,
        pagesize: 15,
        total: 0
      },
      comp: null,
      goodsList: [], // 用于回填添加商品列表复选框
    };
  },
  created() {},
  watch: {},
  mounted() {
    console.log(this.price_type, "mounted");
    this.comp = this.$store.state.loginUser.comp;
    this.getTxglList();
  },
  methods: {
    setTableIndex(arr, index) {
      arr.forEach((item, _index) => {
        item.index = _index;
        if (_index === arr.length - 1) item.last = true;
        if (item.children) {
          this.setTableIndex(item.children, item.index);
        }
      });
    },
    // 商品分类排序 type up:上升 down:下降
    /**
     * @desc 商品分类排序 逻辑:上下行交换 order_no 如果一样则被执行行数据对应+-1
     * @param type{String} up:向上移动 down:向下移动
     * @param row{Object} 当前移动行数据
     * @param index{Number} 当前移动行 index
     */
    async txgl_sort(type, row, index) {
      console.log(type, row, index);
      let siblingList = this.txglList; //同级数组 默认商品分类层级
      //子商品分类  需要重新找出同级元素
      if (row.parentId) {
        const parentRow = this.txglList.find(item => item.id === row.parentId);
        siblingList = parentRow.children;
      }
      if (type === "up") {
        if(index!=0){
            siblingList[index] = siblingList.splice(index-1, 1, siblingList[index])[0];
        }
      } else {
        if(index!=siblingList.length-1){
            siblingList[index] = siblingList.splice(index+1, 1, siblingList[index])[0];
        }
      }
      let sortList = [];
      siblingList.forEach((e,idx) => {
          sortList.push({ id:e.id, order:idx+1,name:e.name })
      });
      orgcatSort(sortList).then(rst => {
          this.getTxglList();
      }).catch(err => {
          this.$message.error(err.message);
      });
    },
    //获取商品分类列表
    async getTxglList() {
      classifyList(this.comp.id).then(res => {
        if (res && res.length>0) {
          this.txglList = fetchTree(res, null, { name: { dest: 'name' }, id: { dest: 'id' }, parent: 'parentId' });
          this.txglList.forEach((e)=>{
            if (e.children && e.children.length>0) {
              e.children.forEach((n)=>{
                n.pLength = e.children.length;
              })
            }
          })
          console.log("txglList",this.txglList);
        }
      }).catch(err => {
          this.$message.error(err.message);
      });
    },
    add_txgl(row) {
      console.log(row);
      // if (this.$store.state.loginUser.comp.cert_stat!=2) {
      //     this.$message.error("企业未认证");
      //     return;
      // }
      this.popTitle = "新增商品分类";
      this.ruleForm = {
        parentId: row?.id || null,
        name: "",
        icon: "",
        order: 1
      };
      this.popVisible = true;
    },
    edit_txgl(row) {
      // console.log(row);
      // if (this.$store.state.loginUser.comp.cert_stat != 2) {
      //   this.$message.error("企业未认证");
      //   return;
      // }
      this.ruleForm = {
        id: row.id,
        parentId: row.parentId,
        name: row.name,
        icon: row.icon,
        order: row.order
      };
      this.popTitle = "修改商品分类";
      this.popVisible = true;
      //   console.log(this.ruleForm);
    },
    //删除商品分类
    del_txgl(item) {
      // if (this.$store.state.loginUser.comp.cert_stat != 2) {
      //   this.$message.error("企业未认证");
      //   return;
      // }
      this.$confirm(`确认要删除该分类吗?`, "温馨提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(async () => {
        const param = { id: item.id };
        orgcatDel(param)
          .then(res => {
            this.$message.success("删除成功!");
            this.getTxglList();
          })
          .catch(err => {
            this.$message.error(err.message);
          });
      });
    },
    //成功添加商品分类 type: -cancel 取消
    hide(type) {
      this.popVisible = false;
      if (type !== "cancel") this.getTxglList();
    },
    add_saleGood(item) {
      const param = {
        custom_cat_no: item.id,
        page: 0,
        pagesize: 999
      };
      listFxProduct(param).then(res => {
        this.goodsList = res.list;
        this.set_id = item.id;
        this.popTitle = "添加现有商品";
        this.popVisible = true;
      }).catch(err => {
        this.$message.error(err.message);
      });
    },
    //去商品列表
    async goGoodList(row) {
      console.log("row",row);
      let name = row.data.path.reduce((pre,cur)=>{
        pre.push(cur.name);
        return pre;
      },[])
      name = name.join(",")
      console.log("name",name);
      if (name) {
        if (this.comp.comp_type==3) {
          this.$router.push({
            path: "/agent/txgl/goodCat",
            query: { set_id: row.id, price_type: this.price_type,name:name }
          });
        } else if (this.comp.comp_type==2) {
          this.$router.push({
            path: "/desgin/txgl/goodCat",
            query: { set_id: row.id, price_type: this.price_type,name:name }
          });
        } else {
          this.$router.push({
            path: "/factory/txgl/goodcat",
            query: { set_id: row.id, price_type: this.price_type,name:name }
          });
        }
      }
    },
  }
};
</script>
<style scoped lang="less">
.sort-module {
  .cats {
    .down {
      transform: rotate(90deg);
    }
    .up {
      transform: rotate(-90deg);
    }
  }
  .goods {
    .down {
      transform: rotate(0deg);
    }
    .up {
      transform: rotate(180deg);
    }
  }
  div {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    width: 40px;
    i {
      color: #016fff;
      display: block;
      cursor: pointer;
      &.disabled {
        pointer-events: none;
        cursor: default;
        color: #cccccc;
      }
    }
  }
}
</style>
<style scoped>
.icon {
  font-size: 13px;
}
.select_list {
  margin: 20px;
}
.select_list > div {
  padding: 0 15px;
  height: 34px;
  line-height: 34px;
  cursor: pointer;
  border: 1px solid rgb(64, 158, 255);
  color: rgb(64, 158, 255);
  border-radius: 4px;
  margin: 8px 8px;
}
.select_list > div.active {
  background: rgb(64, 158, 255);
  color: #fff;
}
.select_list > div.add_item {
  border: 1px dashed #999;
  color: #777;
}
.select_item {
  position: relative;
}
.select_item i {
  position: absolute;
  top: -10px;
  right: -9px;
  font-size: 14px;
  color: #fff;
  display: inline-block;
  vertical-align: top;
  width: 16px;
  height: 16px;
  text-align: center;
  line-height: 16px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.4);
}
.list >>> .el-table .cell {
  text-align: center;
}
</style>
